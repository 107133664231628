import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./config/i18n";
import { HashRouter, Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Home from "./components/home";

import About from "./components/about";
import Service from "./components/service";
import ServiceDetails from "./components/service-details";
import Portfolio from "./components/portfolio";
import PortfolioV2 from "./components/portfolio-v2";
import PortfolioDetails from "./components/portfolio-details";
import Team from "./components/team";
import TeamDetails from "./components/team-details";
import Faq from "./components/faq";
import ComingSoon from "./components/coming-soon";
import Error from "./components/404";
import Location from "./components/location";

import Shop from "./components/shop";
import ShopGrid from "./components/shop-grid";
import ProdductDetails from "./components/product-details";
import ShopLeftSidebar from "./components/shop-left-sidebar";
import ShopRightSidebar from "./components/shop-right-sidebar";

import BlogGrid from "./components/blog-grid";
import BlogLeftSidebar from "./components/blog-left-sidebar";
import BlogRightSidebar from "./components/blog-right-sidebar";
import Blog from "./components/blog";

import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import MyAccount from "./components/my-account";
import Login from "./components/login";
import Register from "./components/register";
import AddListing from "./components/add-listing";
import Wishlist from "./components/wishlist";
import OrderTracking from "./components/order-tracking";
import History from "./components/history";
import "antd/dist/reset.css";
import { TokenProvider } from "./context/tokenVT";
import { UserProvider } from "./context/user";
import { ConfigurationProvider } from "./context/configurations";
import Terms from "./components/terms";

class Root extends Component {
  render() {
    const queryClient = new QueryClient();
    return (
      <TokenProvider>
        <UserProvider>
          <ConfigurationProvider>
            <QueryClientProvider client={queryClient}>
              <HashRouter basename="/">
                <div>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/service" component={Service} />
                    <Route path="/service-details" component={ServiceDetails} />
                    <Route path="/portfolio" component={Portfolio} />
                    <Route path="/portfolio-v2" component={PortfolioV2} />
                    <Route
                      path="/portfolio-details"
                      component={PortfolioDetails}
                    />
                    <Route path="/team" component={Team} />
                    <Route path="/team-details" component={TeamDetails} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/coming-soon" component={ComingSoon} />
                    <Route path="/404" component={Error} />
                    <Route path="/location" component={Location} />
                    <Route path="/shop" component={ShopLeftSidebar} />
                    <Route path="/shop-grid" component={ShopGrid} />
                    <Route path="/shop-left-sidebar" component={Shop} />
                    <Route
                      path="/shop-right-sidebar"
                      component={ShopRightSidebar}
                    />

                    <Route
                      path="/product-details/:id"
                      component={ProdductDetails}
                    />
                    {/* blog */}
                    <Route path="/blog-grid" component={BlogGrid} />
                    <Route
                      path="/blog-left-sidebar"
                      component={BlogLeftSidebar}
                    />
                    <Route
                      path="/blog-right-sidebar"
                      component={BlogRightSidebar}
                    />
                    <Route path="/blog" component={Blog} />

                    <Route path="/blog-details" component={BlogDetails} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/cart" component={Cart} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/my-account" component={MyAccount} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/add-listing" component={AddListing} />
                    <Route path="/wishlist" component={Wishlist} />
                    <Route path="/order-tracking" component={OrderTracking} />
                    <Route path="/history" component={History} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/:code" component={Home} />
                  </Switch>
                </div>
              </HashRouter>
            </QueryClientProvider>
          </ConfigurationProvider>
        </UserProvider>
      </TokenProvider>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
